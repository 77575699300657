import styled from "styled-components";

export const Table = styled.table`
  font-family: Open Sans, serif;
  color: #575757;
  width: 100%;
  margin-bottom: 2.5rem;
`;

export const THead = styled.thead`
  & > tr {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-right: 1rem;

    & > td {
      display: flex;
      width: 100%;
      flex-direction: row;
      justify-content: space-between;

      & > div {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 4rem;
      }

      &:first-child {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        font-size: 1.25rem;
        font-weight: 600;
        min-width: 11rem;
        margin-right: 2.8rem;
        & > img {
          margin-right: 0.5rem;
          filter: invert(49%) sepia(9%) saturate(890%) hue-rotate(314deg)
            brightness(111%) contrast(92%);
          height: 1.5rem;
        }
      }
    }

    .total {
      font-weight: bold;
    }
  }
`;

export const TBody = styled.tbody`
  width: 100%;
  & > tr:first-child {
    margin-top: 1.25rem;
  }
`;

export const TableRow = styled.tr`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 4rem;
  padding: 1rem;
  box-shadow: 0px 4px 17px -2px rgba(0, 0, 0, 0.08);
  margin-bottom: 0.5rem;
  font-size: 1rem;
  width: 100%;

  &:hover {
    box-shadow: inset 4px 0px 0px 0px #575757,
      0px 4px 17px -2px rgba(0, 0, 0, 0.15);
  }

  & > td {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: flex-start;
    white-space: nowrap;
    & > div {
      width: 4rem;
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    &:first-child {
      min-width: 11rem;
      max-width: 11rem;
      margin-right: 1.75rem;
    }
  }

  .total-result {
    font-weight: bold;
  }
  
  .total-result-number {
    white-space: nowrap;
    position:relative;
    overflow: hidden;
    margin: 0 2px;
  }

  .total-result-number:hover {
    overflow: visible;
    z-index: 100;
    border: 2px solid black;
    background: white;
    max-width:100%;
  }

  .wrong-input {
    background: red;
    transition: opacity 0s;
    -webkit-transition: opacity 0s;
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 80%;
  margin: auto;
  justify-content: flex-end;
  margin-bottom: 10rem;

  & > button {
    margin-left: 1.5rem;
    font-family: "Open Sans", serif;
  }
`;

export const TableContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
