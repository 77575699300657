import React, { useReducer, useEffect } from "react";
import styled from "styled-components";
import Button from "../../../components/Button";
import {
  ButtonsContainer,
  TableRow,
  TBody,
  THead,
  Table,
  TableContainer
} from "../../../components/Table";
import { ChartContainer } from "../../../components/Charts";
import Chart from "react-apexcharts";
import EditableLabel from "../../../components/EditableLabel";
import { mockValues } from "../../../test/mock/page_rooms";
import { parseField } from "../../../utils/utils";

const initialState = {
  months: [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ],
  number_of_rooms: ["0", "0", "0", "0", "0", "0", "0", "0", "0", "0", "0", "0"],
  occupancy: ["0.00", "0.00", "0.00", "0.00", "0.00", "0.00", "0.00", "0.00", "0.00", "0.00", "0.00", "0.00"],
  adr: [
    "0.00",
    "0.00",
    "0.00",
    "0.00",
    "0.00",
    "0.00",
    "0.00",
    "0.00",
    "0.00",
    "0.00",
    "0.00",
    "0.00",
  ],
  total_revenue_line: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
};

const has31Days = ["Jan", "Mar", "May", "Jul", "Aug", "Oct", "Dec"];
const has30Days = ["Apr", "Jun", "Sep", "Nov"];

const reducer = (state, action) => {
  const { index, value } = action.payload;
  switch (action.type) {
    case "number_of_rooms":
      const number_of_rooms = state.number_of_rooms;
      number_of_rooms[index] = value;
      return { ...state, number_of_rooms };
    case "occupancy":
      const occupancy = state.occupancy;
      occupancy[index] = value;
      return { ...state, occupancy };
    case "adr":
      const adr = state.adr;
      adr[index] = value;
      return { ...state, adr };
    case "total_revenue_line":
      const total_revenue_line = state.total_revenue_line;
      total_revenue_line[index] = value;
      return { ...state, total_revenue_line };
    default:
      throw new Error("Invalid action");
  }
};

export default function TableRooms({
  nextPage,
  handleError,
  sumElements,
  calculateAverage,
  setTotalRevenueArray,
  totalRevenueArray
}) {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  const handleChange = (obj) => {
    const { index, value, type } = obj;
    try {
      if (!value) {
        throw new Error("Invalid value");
      }
      switch (type) {
        case "number_of_rooms":
          dispatch({
            type,
            payload: { index, value: parseField(value, "integer") },
          });
          break;
        case "occupancy":
          dispatch({
            type,
            payload: { index, value: parseField(value, "percentage") },
          });
          break;
        case "adr":
          dispatch({
            type,
            payload: { index, value: parseField(value, "money") },
          });
          break;
        default:
          throw new Error("Invalid action");
      }

      let {
        total_revenue_line,
        number_of_rooms,
        occupancy,
        adr,
        months
      } = state;

      let month = months[index];
      let days = has31Days.includes(month) ? 31 : has30Days.includes(month) ? 30 : 28;
      let nRooms = number_of_rooms[index];
      let occ = new Number(occupancy[index]);
      let adrNum = new Number(adr[index].split(",").join(""));

      total_revenue_line[index] = (((nRooms * days) * occ) / 100 * adrNum);

      setTotalRevenueArray(total_revenue_line);

    } catch (err) {
      handleError(err.message);
    }
  };


  return (
    <TableContainer>
      <Table>
        <THead>
          <tr>
            <td>
              <img src="/angle-double-right-solid.svg" alt="Arrow icon" />
              <span>Rooms</span>
            </td>

            {state?.months?.map((item) => (
              <td key={`hd-${item}`}>{item}</td>
            ))}

            <td className="total"></td>
          </tr>
        </THead>
        <TBody>
          <TableRow>
            <td>Number of rooms</td>
            {state?.number_of_rooms?.map((_, index) => (
              <td key={`tb-rooms-num_rooms-${index}`} id={`td-rooms-${index}`}>

                <EditableLabel
                  value={state.number_of_rooms[index]}
                  type="integer"
                  setValue={(item) =>
                    handleChange({
                      type: "number_of_rooms",
                      index,
                      value: item,
                    })
                  }
                />

              </td>
            ))}
            <td title="Average" className="total-result">
              {Math.round(100 * (calculateAverage(state?.number_of_rooms) / 100))}
            </td>
          </TableRow>
          <TableRow>
            <td>Occupancy</td>
            {state?.occupancy?.map((_, index) => (
              <td key={`tb-rooms-occupancy-${index}`} id={`#td-occupancy-${index}`}>
                <EditableLabel
                  hasScallableWidth={true}
                  type="percentage"
                  value={state.occupancy[index]}
                  setValue={(item) =>
                    handleChange({
                      type: "occupancy",
                      index,
                      value: item,
                    })
                  }
                /> %
              </td>
            ))}
            <td title="Average" className="total-result">
              {Math.round(calculateAverage(state?.occupancy) * 100) / 100} %
            </td>
          </TableRow>
          <TableRow>
            <td>ADR</td>
            {state?.adr?.map((_, index) => (
              <td key={`tb-rooms-adr-${index}`}>
                <div>
                  <span>$</span>
                  <EditableLabel
                    value={state.adr[index]}
                    type="money"
                    setValue={(item) =>
                      handleChange({
                        type: "adr",
                        index,
                        value: item,
                      })
                    }
                  />
                </div>
              </td>
            ))}
            <td title="Sum" className="total-result">
              ${parseField(""+Math.round(calculateAverage(state?.adr) * 100) / 100,"money")}
            </td>
          </TableRow>
          <TableRow>
            <td style={{ fontWeight: "bold" }}>Total</td>
            {state?.total_revenue_line.map((_, index) => (
              <td className="total-result-number" key={`total-td-${index}`}>
                {"$" + parseField("" + state.total_revenue_line[index], "money")}
              </td>
            ))}
            <td title="Sum" className="total-result total-result-number">
              {"$" + parseField("" + state.total_revenue_line.reduce(sumElements, 0), "money")}
            </td>
          </TableRow>
        </TBody>
      </Table>

      <ChartContainer>
        <Chart
          options={{
            chart: { 
              id: "basic-bar",
              toolbar: {
                show: true,
                tools: {
                  download: false
                }
              },
            },
            markers: { size: 2 },
            xaxis: {
              categories: state.months,
            },
            yaxis: {
              labels: {formatter:(val) => parseField(""+val, "money")}
            },
            animations: {
              enabled: true,
              easing: "linear",
              dynamicAnimation: {
                speed: 1000,
              },
            },
            colors: ['#00941e'],
            tooltip: {
              custom: function ({ series, seriesIndex, dataPointIndex, w }) {
                return `<div class="tooltip_chart">
                  Total Revenue: <span>$ ${parseField("" + series[seriesIndex][dataPointIndex], "money")}</span>
                </div>`
              }
            }
          }}
          series={[
            {
              name: "Total Revenue",
              data: state.total_revenue_line.map((item) => Number(item)),
            }
          ]}
          type="line"
          width="100%"
          height="100%"
        />
      </ChartContainer>
      <ButtonsContainer>
        <Button label="Next" onClick={() => nextPage({ ...state })} />
      </ButtonsContainer>
    </TableContainer >
  );
}
