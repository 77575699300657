import { useState, useEffect } from "react";
import { toast } from "react-toastify";

import React from "react";

export default function EditableLabel({
  value,
  setValue,
  hasScallableWidth,
  type,
  disabled
}) {
  const [editingValue, setEditingValue] = useState(value);
  const [width, setWidth] = useState(2);
  console.log(width);

  useEffect(() => {
    setEditingValue(value);
  }, [value]);

  useEffect(() => {
    if (hasScallableWidth) {
      setWidth(String(value).length < 4 ? 4 : String(value).length);
    }
  }, [value]);

  const onChange = (event) => {
    if (/^[0-9.]+$/.test(event.target.value) || event.target.value === "") {
      setEditingValue(event.target.value);
      setWidth(event.target.value.length < 4 ? 4 : event.target.value.length);
    }
  };

  const onKeyDown = (event) => {
    if (event.key === "Enter" || event.key === "Escape") {
      event.preventDefault();
      event.target.blur();
    }
  };

  const validateField = (target, type) => {
    let field = target.value;
    const warnInScreen = () => {
      target.offsetParent.classList.add("wrong-input");
      setTimeout(() => target.offsetParent.classList.remove("wrong-input"), 2200);
    };
    if (!field) {
      warnInScreen();
      throw new Error("Invalid value");
    }
    switch (type) {
      case "integer":
        if (Number(field) < 0) {
          warnInScreen();
          throw new Error("Invalid value");
        }
        break;
      case "percentage":
        if (Number(field) < 0 || Number(field) > 100) {
          warnInScreen();
          throw new Error("Invalid value");
        }
        break;
      case "money":
        const formated = new Intl.NumberFormat("en-US", {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
        }).format(field);
        if (!formated) {
          warnInScreen();
          throw new Error("Invalid field");
        }
        break;
      default:
        throw new Error("Type not especified");
    }
  };

  const onBlur = (event) => {
    try {
      if (event.target.value.trim() === "") {
        if (/^[0-9.]+$/.test(event.target.value)) {
          setEditingValue(event.target.value);
        } else {
          setEditingValue(0);
          setWidth(1);
        }
      } else {
        validateField(event.target, type);
        setValue(event.target.value);
      }
    } catch (err) {
      let defaultValue = "";

      switch (type) {
        case "money":
          defaultValue = "0.00";
          break;
        default:
          defaultValue = "0";
      }
      setEditingValue(defaultValue);
      toast.error(err.message);
    }
  };

  return (
    <input
      style={{
        height: "100%",
        minHeight: "1.5rem",
        cursor: "pointer",
        width: hasScallableWidth ? width + "ch" : "100%",
        border: "none",
        fontFamily: "Open Sans, sans-serif",
        backgroundColor: "transparent",
      }}
      type="text"
      size="number"
      value={editingValue}
      onChange={onChange}
      onKeyDown={onKeyDown}
      onBlur={onBlur}
      disabled={disabled}
    />
  );
}
