// export default function mockValues() {
//   return [{
//     name: "Number of rooms",
//     data: [1, 1, 2, 3, 5, 8, 13, 21, 34, 55, 89, 144],
//   },
//   {
//     name: "Occupancy",
//     data: [1, 1, 2, 3, 5, 8, 13, 21, 34, 55, 89, 144],
//   },
//   {
//     name: "ADR",
//     data: [1, 1, 2, 3, 5, 8, 13, 21, 34, 55, 89, 144],
//   }];
// } 
export const mockValues = [{
  name: "Number of rooms",
  data: [1, 1, 2, 3, 5, 8, 13, 21, 34, 55, 89, 144],
},
{
  name: "Occupancy",
  data: [1, 1, 2, 3, 5, 8, 13, 21, 34, 55, 89, 144],
},
{
  name: "ADR",
  data: [1, 1, 2, 3, 5, 8, 13, 21, 34, 55, 89, 144],
}];