import React, { useReducer, useEffect } from "react";
import styled from "styled-components";
// import EditableLabel from "react-inline-editing";
import Chart from "react-apexcharts";
import { mockValues } from "../../../test/mock/page_rooms";
import Button from "../../../components/Button";
import { parseField } from "../../../utils/utils";

const daysOnMonths = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

const formatNumber = (number) => {
  return new Intl.NumberFormat("en-US", {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  }).format(number);
};

const totalRoomRevenue = (obj) => {
  return daysOnMonths.map((item, index) => {
    return (
      Number(obj["number_of_rooms"][index]) *
      Number(daysOnMonths[index]) *
      Number(obj["occupancy"][index]) *
      Number(obj["adr"][index].split(",").join(""))
    ) * 0.01;
  })
    .reduce((total, value) => total + value, 0);
};

const totalOtherRevenue = (obj) => {
  const arrayRevenuesToSum = [
    "food_and_beverage",
    "telephone",
    "safe_revenue",
    "movies_and_games",
    "valet_laundry",
    "gift_shop",
    "vending_income",
    "banquet_room_rental",
    "interest_income",
    "miscellaneous",
  ];
  return arrayRevenuesToSum
    .map((item) => {
      return obj[item].reduce((total, value) => total + Number(
        typeof value === "string" ? value.split(",").join("") : value
      ), 0);
    })
    .reduce((total, value) => total + value, 0);
};

const totalRevenue = (obj) => {
  return Number(totalOtherRevenue(obj)) + Number(totalRoomRevenue(obj));
};

const totalExpenses = (obj) => {
  const arrayRevenuesToSum = [
    "payroll",
    "rent_mortgage",
    "marketing",
    "maintenance",
    "ins_taxes",
    "supplies_room",
    "supplies_office",
    "utilities",
    "bank_charges",
    "merchant_charges",
    "fees_memberships",
    "franchise_fees",
  ];
  return arrayRevenuesToSum
    .map((item) => {
      return obj[item].reduce((total, value) => total + Number(
        typeof value === "string" ? value.split(",").join("") : value
      ), 0);
    })
    .reduce((total, value) => total + value, 0);
};

const netProfits = (obj) => {
  return totalRevenue(obj) - totalExpenses(obj);
};

const netProfitsPercentage = (obj) => {
  return (netProfits(obj) / totalRevenue(obj)) * 100 || 0;
};

export default function TableReports({
  nextPage,
  backPage,
  data
}) {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [data]);

  return (
    <TableContainer>
      <Table>
        <THead>
          <tr>
            <td>
              <img src="/angle-double-right-solid.svg" alt="Arrow icon" />
              Total Revenue
            </td>
          </tr>
        </THead>
        <ChartContainer>
          <Chart
            options={{
              chart: {
                id: "basic-bar",
                toolbar: {
                  show: true,
                  tools: {
                    download: false
                  }
                },
              },
              markers: { size: 2 },
              xaxis: {
                categories: data.months,
              },
              yaxis: {
                labels: { formatter: (val) => parseField("" + val, "money") }
              },
              tooltip: {
                custom: function ({ series, seriesIndex, dataPointIndex, w }) {
                  return `<div class="tooltip_chart">
                    Total Revenue: <span>$ ${parseField("" + series[0][dataPointIndex], "money")}</span>
                    <br/>
                    Total Expense: <span>$ ${parseField("" + series[1][dataPointIndex], "money")}</span>
                    <br/>
                    Net Profits: <span>$ ${parseField("" + series[2][dataPointIndex], "money")}</span>
                  </div>`
                }
              },
              animations: {
                enabled: true,
                easing: "linear",
                dynamicAnimation: {
                  speed: 1000,
                },
              },
              colors: ['#00941e', '#ff3700', "#e6cf00"]
            }}
            series={[
              {
                name: "Total Revenue",
                data: data.total_revenue_line.map((item) => Number(item)),
              },
              {
                name: "Total Expense",
                data: data.total_expenses.map((item) => Number(item)),
              },
              {
                name: "Net Profits",
                data: data.net_profits_line.map((item) => Number(item)),
              },
            ]}
            type="line"
            width="100%"
            height="100%"
          />
        </ChartContainer>

        <THead>
          <tr>
            <td>
              <img src="/angle-double-right-solid.svg" alt="Arrow icon" /> KPIs
            </td>
          </tr>
        </THead>
        <TBody>
          <TableRow>
            <KpiBox>
              Average Occupancy
              <p>{formatNumber(
                data.occupancy.reduce((accumulator, a) => {
                  let acc = new Number(accumulator);
                  let act = new Number(typeof a === "string" ? a.split(",").join("") : a);
                  return acc + act;
                }) / data.occupancy.length
              )}%</p>
            </KpiBox>
            <KpiBox>
              Average ADR
              <p>
                ${formatNumber(
                  data.adr.reduce((accumulator, a) => {
                    let acc = new Number(accumulator);
                    let act = new Number(typeof a === "string" ? a.split(",").join("") : a);
                    return acc + act;
                  }) / data.adr.length
                )}
              </p>
            </KpiBox>
            <KpiBox>
              Total Room Revenue
              <p>${formatNumber(totalRoomRevenue(data))}</p>
            </KpiBox>
          </TableRow>
          <TableRow>
            <KpiBox>
              Total Other Revenue
              <p>${formatNumber(totalOtherRevenue(data))}</p>
            </KpiBox>
            <KpiBox>
              Total Revenue
              <p>${formatNumber(totalRevenue(data))}</p>
            </KpiBox>
            <KpiBox>
              Total Expense
              <p>${formatNumber(totalExpenses(data))}</p>
            </KpiBox>
          </TableRow>
          <TableRow>
            <KpiBox>
              Net Profits
              <p>${formatNumber(netProfits(data))}</p>
            </KpiBox>
            <KpiBox>
              Net Profits Percentage
              <p>{formatNumber(netProfitsPercentage(data))}%</p>
            </KpiBox>
          </TableRow>
        </TBody>
        <Button label="Back" variant="secondary" onClick={() => backPage({})} />
      </Table>
    </TableContainer>
  );
}

const Table = styled.table`
  font: Open Sans, serif;
  color: #575757;
  width: 100%;
  margin-bottom: 15rem;
`;

const THead = styled.thead`
  & > tr {
    display: flex;
    flex-direction: row;
    align-items: center;

    & > td:first-child {
      display: flex;
      flex-direction: row;
      align-items: center;
      font-size: 1.25rem;
      font-weight: 600;
      min-width: 13rem;
      margin-right: 2.8rem;
      & > img {
        margin-right: 0.5rem;
        filter: invert(49%) sepia(9%) saturate(890%) hue-rotate(314deg)
          brightness(111%) contrast(92%);
        height: 1.5rem;
      }
    }

    & > div {
      display: flex;
      width: 100%;
      flex-direction: row;
      justify-content: space-between;
      & > td {
        width: 4rem;
      }
    }
  }
`;

const TBody = styled.tbody`
  width: 100%;
  & > tr:first-child {
    margin-top: 1.25rem;
  }
`;

const TableRow = styled.tr`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 2rem;
  font-size: 1rem;
  width: 100%;
`;

const KpiBox = styled.td`
  height: 6.25rem;
  width: 18.875rem;
  padding: 1rem;
  box-shadow: 0px 4px 17px -2px rgba(0, 0, 0, 0.08);
  font-size: 16px;
  font-weight: 600;
  margin-right: 3rem;

  &:hover {
    box-shadow: inset 4px 0px 0px 0px #575757,
      0px 4px 17px -2px rgba(0, 0, 0, 0.15);
  }

  & > p {
    font-size: 30px;
  }
`;
const TableContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const ChartContainer = styled.div`
  width: 100%;
  height: 24rem;
  margin-bottom: 2.5rem;
`;
