import React, { useReducer, useEffect } from "react";
import styled from "styled-components";
import Chart from "react-apexcharts";
import {
  ButtonsContainer,
  TableRow,
  TBody,
  THead,
  Table,
  TableContainer
} from "../../../components/Table";
import { ChartContainer } from "../../../components/Charts";
import EditableLabel from "../../../components/EditableLabel";
import Button from "../../../components/Button";
import { parseField } from "../../../utils/utils";

const initialState = {
  months: [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ],
  payroll: [
    "0.00",
    "0.00",
    "0.00",
    "0.00",
    "0.00",
    "0.00",
    "0.00",
    "0.00",
    "0.00",
    "0.00",
    "0.00",
    "0.00",
  ],
  rent_mortgage: [
    "0.00",
    "0.00",
    "0.00",
    "0.00",
    "0.00",
    "0.00",
    "0.00",
    "0.00",
    "0.00",
    "0.00",
    "0.00",
    "0.00",
  ],
  marketing: [
    "0.00",
    "0.00",
    "0.00",
    "0.00",
    "0.00",
    "0.00",
    "0.00",
    "0.00",
    "0.00",
    "0.00",
    "0.00",
    "0.00",
  ],
  maintenance: [
    "0.00",
    "0.00",
    "0.00",
    "0.00",
    "0.00",
    "0.00",
    "0.00",
    "0.00",
    "0.00",
    "0.00",
    "0.00",
    "0.00",
  ],
  ins_taxes: [
    "0.00",
    "0.00",
    "0.00",
    "0.00",
    "0.00",
    "0.00",
    "0.00",
    "0.00",
    "0.00",
    "0.00",
    "0.00",
    "0.00",
  ],
  supplies_room: [
    "0.00",
    "0.00",
    "0.00",
    "0.00",
    "0.00",
    "0.00",
    "0.00",
    "0.00",
    "0.00",
    "0.00",
    "0.00",
    "0.00",
  ],
  supplies_office: [
    "0.00",
    "0.00",
    "0.00",
    "0.00",
    "0.00",
    "0.00",
    "0.00",
    "0.00",
    "0.00",
    "0.00",
    "0.00",
    "0.00",
  ],
  utilities: [
    "0.00",
    "0.00",
    "0.00",
    "0.00",
    "0.00",
    "0.00",
    "0.00",
    "0.00",
    "0.00",
    "0.00",
    "0.00",
    "0.00",
  ],
  bank_charges: [
    "0.00",
    "0.00",
    "0.00",
    "0.00",
    "0.00",
    "0.00",
    "0.00",
    "0.00",
    "0.00",
    "0.00",
    "0.00",
    "0.00",
  ],
  merchant_charges: [
    "0.00",
    "0.00",
    "0.00",
    "0.00",
    "0.00",
    "0.00",
    "0.00",
    "0.00",
    "0.00",
    "0.00",
    "0.00",
    "0.00",
  ],
  fees_memberships: [
    "0.00",
    "0.00",
    "0.00",
    "0.00",
    "0.00",
    "0.00",
    "0.00",
    "0.00",
    "0.00",
    "0.00",
    "0.00",
    "0.00",
  ],
  franchise_fees: [
    "0.00",
    "0.00",
    "0.00",
    "0.00",
    "0.00",
    "0.00",
    "0.00",
    "0.00",
    "0.00",
    "0.00",
    "0.00",
    "0.00",
  ],
  total_expenses: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  net_profits_line: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
};

const reducer = (state, action) => {
  const { index, value } = action.payload;
  switch (action.type) {
    case "payroll":
      const payroll = state.payroll;
      payroll[index] = value;
      return { ...state, payroll };
    case "rent_mortgage":
      const rent_mortgage = state.rent_mortgage;
      rent_mortgage[index] = value;
      return { ...state, rent_mortgage };
    case "marketing":
      const marketing = state.marketing;
      marketing[index] = value;
      return { ...state, marketing };
    case "maintenance":
      const maintenance = state.maintenance;
      maintenance[index] = value;
      return { ...state, maintenance };
    case "ins_taxes":
      const ins_taxes = state.ins_taxes;
      ins_taxes[index] = value;
      return { ...state, ins_taxes };
    case "supplies_room":
      const supplies_room = state.supplies_room;
      supplies_room[index] = value;
      return { ...state, supplies_room };
    case "supplies_office":
      const supplies_office = state.supplies_office;
      supplies_office[index] = value;
      return { ...state, supplies_office };
    case "utilities":
      const utilities = state.utilities;
      utilities[index] = value;
      return { ...state, utilities };
    case "bank_charges":
      const bank_charges = state.bank_charges;
      bank_charges[index] = value;
      return { ...state, bank_charges };
    case "merchant_charges":
      const merchant_charges = state.merchant_charges;
      merchant_charges[index] = value;
      return { ...state, merchant_charges };
    case "fees_memberships":
      const fees_memberships = state.fees_memberships;
      fees_memberships[index] = value;
      return { ...state, fees_memberships };
    case "franchise_fees":
      const franchise_fees = state.franchise_fees;
      franchise_fees[index] = value;
      return { ...state, franchise_fees };
    case "net_profits_line":
      const net_profits_line = state.net_profits_line;
      net_profits_line[index] = value;
      return { ...state, net_profits_line };
    default:
      throw new Error("Invalid action");
  }
};

export default function TableRooms({ nextPage,
  backPage,
  handleError,
  sumElements,
  totalRevenueArray,
  netProfitsArray,
  setTotalRevenueArray,
  setNetProfitsArray,
  currentSavedData,
  setCurrentSavedData
}) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const handleChange = (obj) => {
    const { index, value, type } = obj;
    try {
      if (!value) {
        throw new Error("Invalid value");
      }
      dispatch({
        type,
        payload: { index, value: parseField(value, "money") },
      });

      let {
        payroll,
        rent_mortgage,
        marketing,
        maintenance,
        ins_taxes,
        supplies_room,
        supplies_office,
        utilities,
        bank_charges,
        merchant_charges,
        fees_memberships,
        franchise_fees,
        total_expenses,
        net_profits_line
      } = state;

      total_expenses[index] = (
        new Number(payroll[index].split(",").join("")) +
        new Number(rent_mortgage[index].split(",").join("")) +
        new Number(marketing[index].split(",").join("")) +
        new Number(maintenance[index].split(",").join("")) +
        new Number(ins_taxes[index].split(",").join("")) +
        new Number(supplies_room[index].split(",").join("")) +
        new Number(supplies_office[index].split(",").join("")) +
        new Number(utilities[index].split(",").join("")) +
        new Number(bank_charges[index].split(",").join("")) +
        new Number(merchant_charges[index].split(",").join("")) +
        new Number(fees_memberships[index].split(",").join("")) +
        new Number(franchise_fees[index].split(",").join(""))
      );

      let temporaryProfits = netProfitsArray;
      temporaryProfits[index] = totalRevenueArray[index] - total_expenses[index];
      net_profits_line[index] = temporaryProfits[index];
      temporaryProfits = temporaryProfits.map((_, i) => totalRevenueArray[i] - total_expenses[i]);
      setNetProfitsArray(temporaryProfits);

    } catch (err) {
      handleError(err.message);
    }
  };

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  return (
    <TableContainer>
      <Table>
        <THead>
          <tr>
            <td>
              <img src="/angle-double-right-solid.svg" alt="Arrow icon" />{" "}
              Expenses
            </td>
            {state?.months.map((item) => (
              <td className="months-head" key={`hd-${item}`}>
                {item}
              </td>
            ))}
            <td className="total">Total</td>
          </tr>
        </THead>
        <TBody>
          <TableRow>
            <td>Payroll</td>
            {state?.payroll?.map((item, index) => (
              <td key={`tb-rooms-num_rooms-${index}`}>
                <span>$</span>
                <EditableLabel
                  value={state.payroll[index]}
                  type="money"
                  setValue={(item) =>
                    handleChange({
                      type: "payroll",
                      index,
                      value: item,
                    })
                  }
                />
              </td>
            ))}
            <td className="total-result">
              ${parseField(""+state?.payroll.reduce(sumElements, 0),"money")}</td>
          </TableRow>
          <TableRow>
            <td>Rent/Mortgage</td>
            {state?.rent_mortgage?.map((item, index) => (
              <td key={`tb-rooms-num_rooms-${index}`}>
                <span>$</span>
                <EditableLabel
                  value={state.rent_mortgage[index]}
                  type="money"
                  setValue={(item) =>
                    handleChange({
                      type: "rent_mortgage",
                      index,
                      value: item,
                    })
                  }
                />
              </td>
            ))}
            <td className="total-result">
              ${parseField(""+state?.rent_mortgage.reduce(sumElements, 0),"money")}
            </td>
          </TableRow>
          <TableRow>
            <td>Marketing</td>
            {state?.marketing?.map((item, index) => (
              <td key={`tb-other-marketing-${index}`}>
                <span>$</span>
                <EditableLabel
                  value={state.marketing[index]}
                  type="money"
                  setValue={(item) =>
                    handleChange({
                      type: "marketing",
                      index,
                      value: item,
                    })
                  }
                />
              </td>
            ))}
            <td className="total-result">
              ${parseField(""+state?.marketing.reduce(sumElements, 0),"money")}
            </td>
          </TableRow>
          <TableRow>
            <td>Maintenance</td>
            {state?.maintenance?.map((item, index) => (
              <td key={`tb-other-maintenance-${index}`}>
                <span>$</span>
                <EditableLabel
                  value={state.maintenance[index]}
                  type="money"
                  setValue={(item) =>
                    handleChange({
                      type: "maintenance",
                      index,
                      value: item,
                    })
                  }
                />
              </td>
            ))}
            <td className="total-result">
              ${parseField(""+state?.maintenance.reduce(sumElements, 0),"money")}
            </td>
          </TableRow>
          <TableRow>
            <td>Ins & Taxes</td>
            {state?.ins_taxes?.map((item, index) => (
              <td key={`tb-other-ins_taxes-${index}`}>
                <span>$</span>
                <EditableLabel
                  value={state.ins_taxes[index]}
                  type="money"
                  setValue={(item) =>
                    handleChange({
                      type: "ins_taxes",
                      index,
                      value: item,
                    })
                  }
                />
              </td>
            ))}
            <td className="total-result">
              ${parseField(""+state?.ins_taxes.reduce(sumElements, 0),"money")}
            </td>
          </TableRow>
          <TableRow>
            <td>Supplies: Room</td>
            {state?.supplies_room?.map((item, index) => (
              <td key={`tb-other-supplies_room-${index}`}>
                <span>$</span>
                <EditableLabel
                  value={state.supplies_room[index]}
                  type="money"
                  setValue={(item) =>
                    handleChange({
                      type: "supplies_room",
                      index,
                      value: item,
                    })
                  }
                />
              </td>
            ))}
            <td className="total-result">
              ${parseField(""+state?.supplies_office.reduce(sumElements, 0),"money")}
            </td>
          </TableRow>
          <TableRow>
            <td>Supplies: Office</td>
            {state?.supplies_office?.map((item, index) => (
              <td key={`tb-other-supplies_office-${index}`}>
                <span>$</span>
                <EditableLabel
                  value={state.supplies_office[index]}
                  type="money"
                  setValue={(item) =>
                    handleChange({
                      type: "supplies_office",
                      index,
                      value: item,
                    })
                  }
                />
              </td>
            ))}
            <td className="total-result">
              ${parseField(""+state?.supplies_office.reduce(sumElements, 0),"money")}
            </td>
          </TableRow>
          <TableRow>
            <td>Utilities</td>
            {state?.utilities?.map((item, index) => (
              <td key={`tb-other-utilities-${index}`}>
                <span>$</span>
                <EditableLabel
                  value={state.utilities[index]}
                  type="money"
                  setValue={(item) =>
                    handleChange({
                      type: "utilities",
                      index,
                      value: item,
                    })
                  }
                />
              </td>
            ))}
            <td className="total-result">
              ${parseField(""+state?.utilities.reduce(sumElements, 0),"money")}
            </td>
          </TableRow>
          <TableRow>
            <td>Bank Charges</td>
            {state?.bank_charges?.map((item, index) => (
              <td key={`tb-other-bank_charges-${index}`}>
                <span>$</span>
                <EditableLabel
                  value={state.bank_charges[index]}
                  type="money"
                  setValue={(item) =>
                    handleChange({
                      type: "bank_charges",
                      index,
                      value: item,
                    })
                  }
                />
              </td>
            ))}
            <td className="total-result">
              ${parseField(""+state?.bank_charges.reduce(sumElements, 0),"money")}
            </td>
          </TableRow>
          <TableRow>
            <td>Merchant Charges</td>
            {state?.merchant_charges?.map((item, index) => (
              <td key={`tb-other-merchant_charges-${index}`}>
                <span>$</span>
                <EditableLabel
                  value={state.merchant_charges[index]}
                  type="money"
                  setValue={(item) =>
                    handleChange({
                      type: "merchant_charges",
                      index,
                      value: item,
                    })
                  }
                />
              </td>
            ))}
            <td className="total-result">
              ${parseField(""+state?.merchant_charges.reduce(sumElements, 0),"money")}
            </td>
          </TableRow>
          <TableRow>
            <td>Fees & Memberships</td>
            {state?.fees_memberships?.map((item, index) => (
              <td key={`tb-other-fees_memberships-${index}`}>
                <span>$</span>
                <EditableLabel
                  value={state.fees_memberships[index]}
                  type="money"
                  setValue={(item) =>
                    handleChange({
                      type: "fees_memberships",
                      index,
                      value: item,
                    })
                  }
                />
              </td>
            ))}
            <td className="total-result">
              ${parseField(""+state?.fees_memberships.reduce(sumElements, 0),"money")}
            </td>
          </TableRow>
          <TableRow>
            <td>Franchise Fees</td>
            {state?.franchise_fees?.map((item, index) => (
              <td key={`tb-other-fees_memberships-${index}`}>
                <span>$</span>
                <EditableLabel
                  value={state.franchise_fees[index]}
                  type="money"
                  setValue={(item) =>
                    handleChange({
                      type: "franchise_fees",
                      index,
                      value: item,
                    })
                  }
                />
              </td>
            ))}
            <td className="total-result">
              ${parseField(""+state?.franchise_fees.reduce(sumElements, 0),"money")}
            </td>
          </TableRow>
          <TableRow>
            <td className="total-result">Total Expense</td>
            {state?.total_expenses?.map((_, index) => (
              <td key={`td-total-expanses-${index}`}>
                ${parseField(""+state?.total_expenses[index],"money")}
              </td>
            ))}
            <td className="total-result">
              ${parseField(""+state?.total_expenses.reduce(sumElements, 0),"money")}
            </td>
          </TableRow>
          <TableRow>
            <td className="total-result">Total Revenue</td>
            {totalRevenueArray.map((_, index) => (
              <td className="total-result-number" key={`td-total-revenue-${index}`}>
                ${parseField(""+totalRevenueArray[index], "money")}
              </td>
            ))}
            <td className="total-result total-result-number">
              ${parseField(""+totalRevenueArray.reduce(sumElements, 0),"money")}
            </td>
          </TableRow>
          <TableRow>
            <td className="total-result">Net Profits</td>
            {netProfitsArray.map((_, index) => (
              <td className="total-result-number" key={`td-net-profits-${index}`}>
                ${parseField("" + netProfitsArray[index], "money")}
              </td>
            ))}
            <td className="total-result total-result-number">
              ${parseField("" + netProfitsArray.reduce(sumElements, 0), "money")}
            </td>
          </TableRow>
        </TBody>
      </Table>

      <ChartContainer>
        <Chart
          options={{
            chart: {
              id: "basic-bar",
              toolbar: {
                show: true,
                tools: {
                  download: false
                }
              },
            },
            markers: { size: 2 },
            xaxis: {
              categories: state.months,
            },
            yaxis: { labels: { formatter: (val) => parseField("" + val, "money") } },
            animations: {
              enabled: true,
              easing: "linear",
              dynamicAnimation: {
                speed: 1000,
              },
            },
            tooltip: {
              custom: function ({ series, seriesIndex, dataPointIndex, w }) {              
                return `<div class="tooltip_chart">
                  Total Revenue: <span>$ ${parseField("" + series[0][dataPointIndex], "money")}</span>
                  <br/>
                  Total Expense: <span>$ ${parseField("" + series[1][dataPointIndex], "money")}</span>
                  <br/>
                  Net Profits: <span>$ ${parseField("" + series[2][dataPointIndex], "money")}</span>
                </div>`
              }
            },
            colors: ['#00941e', '#ff3700', "#e6cf00"]
          }}
          series={[
            {
              name: "Total Revenue",
              data: totalRevenueArray.map((item) => Number(item)),
            },
            {
              name: "Total Expense",
              data: state.total_expenses.map(item => Number(item))
            },
            {
              name: "Net Profits",
              data: netProfitsArray.map(item => Number(item))
            }
          ]}
          type="line"
          width="100%"
          height="100%"
        />
      </ChartContainer>
      <ButtonsContainer>
        <Button
          label="Back"
          variant="secondary"
          onClick={() => backPage({ ...state })}
        />
        <Button
          label="Calculate Budget"
          onClick={() => {
            state.net_profits_line = netProfitsArray;
            setCurrentSavedData({
              ...currentSavedData,
              total_revenue_array: totalRevenueArray.map((item) => Number(item)),
              total_expenses: state.total_expenses.map(item => Number(item)),

            });
            nextPage({ ...state });
          }}
        />
      </ButtonsContainer>
    </TableContainer>
  );
}
