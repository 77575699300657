const parseField = (field, type) => {
  field = field.split(",").join("");
  switch (type) {
    case "integer":
      return Math.round(Number(field));
    case "percentage":
      return (Number(field).toFixed(2));
    case "money":
      const formated = new Intl.NumberFormat("en-US", {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      }).format(field);
      if (!formated) throw new Error("Invalid field");
      return formated;
    default:
      throw new Error("Type not especified");
  }
};

export { parseField };
