import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import styled from "styled-components";
import TableRooms from "./Tables/TableRooms";
import TableOther from "./Tables/TableOther";
import TableExpenses from "./Tables/TableExpenses";
import TableReports from "./Tables/TableReports";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import TablesHeader from "../../components/TablesHeader";

const api = axios.create({
  baseURL: window.location.href.includes("localhost") ?
    "http://localhost:5001/rates-bb81c/us-central1/getCaptcha"
    : "https://us-central1-budget-effc0.cloudfunctions.net/getCaptchaBudget"
});

const pages = ["rooms", "other_revenues", "expenses", "reports"];

export default function Budget() {
  let params = useParams();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(pages[0]);
  const [previousPage, setPreviousPage] = useState([]);
  let [currentSavedData, setCurrentSavedData] = useState({});
  const [totalRevenueArray, setTotalRevenueArray] = useState([
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0
  ]);
  const [netProfitsArray, setNetProfitsArray] = useState([
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0
  ]);

  const sumElements = (accumulator, a) => {
    let acc = new Number(accumulator);
    let act = new Number(typeof a === "string" ? a.split(",").join("") : a);
    return acc + act;
  };

  const calculateAverage = (arr) => {
    if (Array.isArray(arr)) {
      return arr.reduce(sumElements, 0) / arr.length;
    } else {
      return console.error("Need to be an Array.");
    }
  };

  const handleError = (errMsg) => {
    toast.error(errMsg);
  };

  const verifyDataBeforeChangePage = (obj) => {
    const keys = Object.keys(obj);
    const currentError = [];
    keys.forEach((key) => {
      obj[key].forEach((itemEach) => {
        if (!itemEach || itemEach === `NaN`) {
          currentError.push(itemEach);
        }
      });
    });

    return currentError.length > 0;
  };

  const nextPage = async (item) => {
    const currentPageIndex = pages.indexOf(currentPage);

    if (currentPageIndex <= -1 && currentPageIndex > pages.length) {
      throw new Error("Invalid page");
    }
    // if (verifyDataBeforeChangePage(item)) {
    //   toast.error("All fields need to be valid and required.");
    // } 
    else {
      const previousPageTmp = previousPage;
      previousPageTmp.push(currentPage);
      setPreviousPage(previousPageTmp);
      setCurrentSavedData(Object.assign({ ...currentSavedData, ...item }));
      setCurrentPage(pages[pages.indexOf(currentPage) + 1]);
    }
  };

  const backPage = (item) => {
    if (verifyDataBeforeChangePage(item)) {
      setCurrentSavedData(Object.assign({ ...currentSavedData, ...item }));
    }
    const lastPage = previousPage[previousPage.length - 1];
    setCurrentPage(lastPage);
    let newPreviousArr = previousPage;
    newPreviousArr.pop();
    setPreviousPage(newPreviousArr);
  };

  const verifyCaptcha = async () => {
    return api.post("/", {
      token: params.id,
    });
  };

  useEffect(() => {
    verifyCaptcha().catch(err => {
      window.history.back();
    });
  }, []);
  return (
    <>
      <ToastContainer />
      <BudgetContainer>
        <TablesHeader />
        <MainSection>
          <Watermark />
          <ContainerMain>
            <TableHeader>
              <h1>Budget</h1>
              <span>
                {currentPage !== "reports" &&
                  "Bezla created the Budget to help hoteliers utilize precise forecasts and allocate optimal pricing and revenue management strategies. Enter your predicted revenue and expenses to see a clear picture of the hotel's budget simulation."}
                {currentPage === "reports" &&
                  "Here is the budget simulation for your hotel. If Bezla could be a service to help you achieve these numbers, schedule a call today."}
              </span>
            </TableHeader>
          </ContainerMain>
          <TableContainer>
            {currentPage === "rooms" && (
              <TableRooms
                nextPage={(item) => nextPage(item)}
                backPage={(item) => backPage(item)}
                sumElements={(acc, act) => sumElements(acc, act)}
                calculateAverage={(arr) => calculateAverage(arr)}
                totalRevenueArray={totalRevenueArray}
                setTotalRevenueArray={(arr) => setTotalRevenueArray(arr)}
                handleError={(item) => handleError(item)}
              />
            )}
            {currentPage === "other_revenues" && (
              <TableOther
                nextPage={(item) => nextPage(item)}
                backPage={(item) => backPage(item)}
                calculateAverage={(arr) => calculateAverage(arr)}
                sumElements={(acc, act) => sumElements(acc, act)}
                totalRevenueArray={totalRevenueArray}
                setTotalRevenueArray={(arr) => setTotalRevenueArray(arr)}
                handleError={(item) => handleError(item)}
              />
            )}
            {currentPage === "expenses" && (
              <TableExpenses
                nextPage={(item) => nextPage(item)}
                backPage={(item) => backPage(item)}
                sumElements={(acc, act) => sumElements(acc, act)}
                totalRevenueArray={totalRevenueArray}
                netProfitsArray={netProfitsArray}
                setTotalRevenueArray={(arr) => setTotalRevenueArray(arr)}
                setNetProfitsArray={(arr) => setNetProfitsArray(arr)}
                handleError={(item) => handleError(item)}
                data={currentSavedData}
                setCurrentSavedData={setCurrentSavedData}
              />
            )}
            {currentPage === "reports" && (
              <TableReports
                nextPage={(item) => nextPage(item)}
                backPage={(item) => backPage(item)}
                totalRevenueArray={totalRevenueArray}
                data={currentSavedData}
              />
            )}
          </TableContainer>
        </MainSection>
      </BudgetContainer>
    </>
  );
}

const Watermark = styled.div`
  position: fixed;
  width: 90%;
  height: 90%;
  margin: auto;
  z-index: -1;
  background-size: 30%;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url("/watermark.jpeg");
  opacity: 0.15;
`;

const BudgetContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100vw;  
`;

const BackgroundHeader = styled.header`
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #333333;
  width: 100%;
  z-index: 1000;
`;

const Container = styled.div`
  display: flex;
  align-self: center;
  max-width: 1212px;
  padding: 0 2rem;
`;

const ContainerHeader = styled(Container)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 6rem;
  padding: 0 2rem;
  width: 100%;

  & > a {
    & > img {
      width: 4rem;
      height: auto;
    }
  }
`;

const ContainerMain = styled(Container)`
  margin-top: 3rem;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const Nav = styled.nav`
  display: flex;
  flex-direction: row;
  color: white;

  & > a {
    font-weight: 400;
    padding: 0.5rem;
    margin-right: 1rem;
    font-size: 1.2rem;
    text-decoration: none;
    color: white;
    font-family: "Open Sans", sans-serif;
    white-space: nowrap;

    &:last-of-type {
      margin-right: 0;
    }
  }
  @media (max-width: 925px) {
    display: none;
  }
`;

const MainSection = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const TableHeader = styled.section`
  display: flex;
  flex-direction: column;
  margin-bottom: 2.5rem;

  & > h1 {
    color: #ab8585;
    font-size: 1.5rem;
    line-height: 2rem;
    font-family: "Playfair Display", serif;
    font-weight: 400;
  }

  & > span {
    font-family: "Open Sans", serif;
    line-height: 1.375rem;
    margin-top: 0.5rem;
    color: #575757;
  }
`;

const TableContainer = styled.div`
  display: flex;
  flex-direction: row;
  max-width: 96%;
  margin: auto auto;
`;

const BurguerMenu = styled.nav`
  @media (min-width: 925px) {
    display: none;
  }
  & > button {
    padding: 0.25rem 0.75rem;
    background-color: transparent;
    border-style: solid;
    border-color: transparent;
    cursor: pointer;
    & > img {
      width: 1.5rem;
      height: 1.5rem;
      filter: invert(100%) sepia(0%) saturate(7500%) hue-rotate(209deg)
        brightness(100%) contrast(102%);
    }
  }
`;

const Overlay = styled.div`
  position: absolute;
  height: 100vh;
  width: 100vw;
  background-color: rgba(22, 22, 22, 0.9);
  top: 0;
  left: 0;
  z-index: 2;
  display: ${({ isOpen }) => (isOpen ? "flex" : "none")};
  flex-direction: column;
  color: #f7f7f7;

  & > div {
    padding: 0.625rem 0.625rem 0.625rem 2.8125rem;
    border-bottom: 1px solid rgba(76, 76, 76, 0.5);
    &:first-child {
      border-bottom: none;
      display: flex;
      flex-direction: row-reverse;
      align-content: flex-end;

      & > button {
        padding: 0.625rem 2.8125rem 0.625rem 2.8125rem;
        background-color: transparent;
        border-style: solid;
        border-color: transparent;
        cursor: pointer;
        & > img {
          width: 1.5rem;
          height: 1.5rem;
          filter: invert(100%) sepia(0%) saturate(7500%) hue-rotate(209deg)
            brightness(100%) contrast(102%);
        }
      }
    }

    & > a {
      line-height: 1.6;
      font-size: 2rem;
      color: #f7f7f7;
      text-decoration: none;
      font-family: "Open Sans", sans-serif;
    }
  }
`;
