import React, { useReducer, useState, useEffect } from "react";
import styled from "styled-components";
import Chart from "react-apexcharts";
import {
  ButtonsContainer,
  TableRow,
  TBody,
  THead,
  Table,
  TableContainer
} from "../../../components/Table";
import { ChartContainer } from "../../../components/Charts";
import EditableLabel from "../../../components/EditableLabel";
import Button from "../../../components/Button";
import { parseField } from "../../../utils/utils";

const initialState = {
  months: [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ],
  food_and_beverage: [
    "0.00",
    "0.00",
    "0.00",
    "0.00",
    "0.00",
    "0.00",
    "0.00",
    "0.00",
    "0.00",
    "0.00",
    "0.00",
    "0.00",
  ],
  telephone: [
    "0.00",
    "0.00",
    "0.00",
    "0.00",
    "0.00",
    "0.00",
    "0.00",
    "0.00",
    "0.00",
    "0.00",
    "0.00",
    "0.00",
  ],
  safe_revenue: [
    "0.00",
    "0.00",
    "0.00",
    "0.00",
    "0.00",
    "0.00",
    "0.00",
    "0.00",
    "0.00",
    "0.00",
    "0.00",
    "0.00",
  ],
  movies_and_games: [
    "0.00",
    "0.00",
    "0.00",
    "0.00",
    "0.00",
    "0.00",
    "0.00",
    "0.00",
    "0.00",
    "0.00",
    "0.00",
    "0.00",
  ],
  valet_laundry: [
    "0.00",
    "0.00",
    "0.00",
    "0.00",
    "0.00",
    "0.00",
    "0.00",
    "0.00",
    "0.00",
    "0.00",
    "0.00",
    "0.00",
  ],
  gift_shop: [
    "0.00",
    "0.00",
    "0.00",
    "0.00",
    "0.00",
    "0.00",
    "0.00",
    "0.00",
    "0.00",
    "0.00",
    "0.00",
    "0.00",
  ],
  vending_income: [
    "0.00",
    "0.00",
    "0.00",
    "0.00",
    "0.00",
    "0.00",
    "0.00",
    "0.00",
    "0.00",
    "0.00",
    "0.00",
    "0.00",
  ],
  banquet_room_rental: [
    "0.00",
    "0.00",
    "0.00",
    "0.00",
    "0.00",
    "0.00",
    "0.00",
    "0.00",
    "0.00",
    "0.00",
    "0.00",
    "0.00",
  ],
  interest_income: [
    "0.00",
    "0.00",
    "0.00",
    "0.00",
    "0.00",
    "0.00",
    "0.00",
    "0.00",
    "0.00",
    "0.00",
    "0.00",
    "0.00",
  ],
  miscellaneous: [
    "0.00",
    "0.00",
    "0.00",
    "0.00",
    "0.00",
    "0.00",
    "0.00",
    "0.00",
    "0.00",
    "0.00",
    "0.00",
    "0.00",
  ],
  total_other_revenue: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
};

const reducer = (state, action) => {
  const { index, value } = action.payload;

  switch (action.type) {
    case "food_and_beverage":
      const food_and_beverage = state.food_and_beverage;
      food_and_beverage[index] = value;
      return { ...state, food_and_beverage };
    case "telephone":
      const telephone = state.telephone;
      telephone[index] = value;
      return { ...state, telephone };
    case "safe_revenue":
      const safe_revenue = state.safe_revenue;
      safe_revenue[index] = value;
      return { ...state, safe_revenue };
    case "movies_and_games":
      const movies_and_games = state.movies_and_games;
      movies_and_games[index] = value;
      return { ...state, movies_and_games };
    case "valet_laundry":
      const valet_laundry = state.valet_laundry;
      valet_laundry[index] = value;
      return { ...state, valet_laundry };
    case "gift_shop":
      const gift_shop = state.gift_shop;
      gift_shop[index] = value;
      return { ...state, gift_shop };
    case "vending_income":
      const vending_income = state.vending_income;
      vending_income[index] = value;
      return { ...state, vending_income };
    case "banquet_room_rental":
      const banquet_room_rental = state.banquet_room_rental;
      banquet_room_rental[index] = value;
      return { ...state, banquet_room_rental };
    case "interest_income":
      const interest_income = state.interest_income;
      interest_income[index] = value;
      return { ...state, interest_income };
    case "miscellaneous":
      const miscellaneous = state.miscellaneous;
      miscellaneous[index] = value;
      return { ...state, miscellaneous };
    case "total_other_revenue":
      const total_other_revenue = state.total_other_revenue;
      total_other_revenue[index] = value;
      return { ...state, total_other_revenue };
    default:
      throw new Error("Invalid action");
  }
};

export default function TableOther({
  nextPage,
  backPage,
  handleError,
  sumElements,
  totalRevenueArray,
  calculateAverage,
  setTotalRevenueArray
}) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [temporaryTotalRevenue, SetTemporalTotalRevenue] = useState(totalRevenueArray);
  const [otherPlusTotalRevenue, SetOtherPlusTotalRevenue] = useState(totalRevenueArray);

  const handleChange = (obj) => {
    const { index, value, type } = obj;

    try {
      if (!value) {
        throw new Error("Invalid value");
      }
      dispatch({
        type,
        payload: { index, value: parseField(value, "money") },
      });


      let {
        food_and_beverage,
        telephone,
        safe_revenue,
        movies_and_games,
        valet_laundry,
        gift_shop,
        vending_income,
        banquet_room_rental,
        interest_income,
        miscellaneous,
        total_other_revenue
      } = state;

      total_other_revenue[index] = (
        new Number(food_and_beverage[index].split(",").join("")) +
        new Number(telephone[index].split(",").join("")) +
        new Number(safe_revenue[index].split(",").join("")) +
        new Number(movies_and_games[index].split(",").join("")) +
        new Number(valet_laundry[index].split(",").join("")) +
        new Number(gift_shop[index].split(",").join("")) +
        new Number(vending_income[index].split(",").join("")) +
        new Number(banquet_room_rental[index].split(",").join("")) +
        new Number(interest_income[index].split(",").join("")) +
        new Number(miscellaneous[index].split(",").join(""))
      );

      let temp = [...totalRevenueArray];
      temp = temp.map((item, i) => temp[i] + total_other_revenue[i]);
      SetTemporalTotalRevenue(temp);
    } catch (err) {
      handleError(err.message);
    }
  };

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  return (
    <TableContainer>
      <Table>
        <THead>
          <tr>
            <td>
              <img src="/angle-double-right-solid.svg" alt="Arrow icon" /> Other
              revenue
            </td>
            {state?.months?.map((item) => (
              <td className="months-head" key={`hd-${item}`}>
                {item}
              </td>
            ))}
            <td className="total">Total</td>
          </tr>
        </THead>
        <TBody>
          <TableRow>
            <td>Food & Beverage</td>
            {state?.food_and_beverage?.map((item, index) => (
              <td key={`tb-rooms-num_rooms-${index}`}>
                <div>
                  <span>$</span>
                  <EditableLabel
                    value={state.food_and_beverage[index]}
                    type="money"
                    setValue={(item) =>
                      handleChange({
                        type: "food_and_beverage",
                        index,
                        value: item,
                      })
                    }
                  />
                </div>
              </td>
            ))}
            <td className="total-result">
              ${parseField("" + state?.food_and_beverage?.reduce(sumElements, 0), "money")}
            </td>
          </TableRow>
          <TableRow>
            <td>Telephone</td>
            {state?.telephone?.map((item, index) => (
              <td key={`tb-rooms-num_rooms-${index}`}>
                <div>
                  <span>$</span>
                  <EditableLabel
                    value={state.telephone[index]}
                    type="money"
                    setValue={(item) =>
                      handleChange({
                        type: "telephone",
                        index,
                        value: item,
                      })
                    }
                  />
                </div>
              </td>
            ))}
            <td className="total-result">
              ${parseField(""+state?.telephone?.reduce(sumElements, 0),"money")}
            </td>
          </TableRow>
          <TableRow>
            <td>Safe Revenue</td>
            {state?.safe_revenue?.map((item, index) => (
              <td key={`tb-other-safe_revenue-${index}`}>
                <span>$</span>
                <EditableLabel
                  value={state.safe_revenue[index]}
                  type="money"
                  setValue={(item) =>
                    handleChange({
                      type: "safe_revenue",
                      index,
                      value: item,
                    })
                  }
                />
              </td>
            ))}
            <td className="total-result">
              ${parseField(""+state?.safe_revenue.reduce(sumElements, 0),"money")}
            </td>
          </TableRow>
          <TableRow>
            <td>Movies and Games</td>
            {state?.movies_and_games?.map((item, index) => (
              <td key={`tb-other-movies_and_games-${index}`}>
                <span>$</span>
                <EditableLabel
                  value={state.movies_and_games[index]}
                  type="money"
                  setValue={(item) =>
                    handleChange({
                      type: "movies_and_games",
                      index,
                      value: item,
                    })
                  }
                />
              </td>
            ))}
            <td className="total-result">
              ${parseField(""+state?.movies_and_games.reduce(sumElements, 0),"money")}
            </td>
          </TableRow>
          <TableRow>
            <td>Valet Laundry</td>
            {state?.valet_laundry?.map((item, index) => (
              <td key={`tb-other-valet_laundry-${index}`}>
                <span>$</span>
                <EditableLabel
                  value={state.valet_laundry[index]}
                  type="money"
                  setValue={(item) =>
                    handleChange({
                      type: "valet_laundry",
                      index,
                      value: item,
                    })
                  }
                />
              </td>
            ))}
            <td className="total-result">
              ${parseField(""+state?.valet_laundry.reduce(sumElements, 0),"money")}
            </td>
          </TableRow>
          <TableRow>
            <td>Gift Shop</td>
            {state?.gift_shop?.map((item, index) => (
              <td key={`tb-other-gift_shop-${index}`}>
                <span>$</span>
                <EditableLabel
                  value={state.gift_shop[index]}
                  type="money"
                  setValue={(item) =>
                    handleChange({
                      type: "gift_shop",
                      index,
                      value: item,
                    })
                  }
                />
              </td>
            ))}
            <td className="total-result">
              ${parseField(""+state?.gift_shop.reduce(sumElements, 0),"money")}
            </td>
          </TableRow>
          <TableRow>
            <td>Vending Income</td>
            {state?.vending_income?.map((item, index) => (
              <td key={`tb-other-vending_income-${index}`}>
                <span>$</span>
                <EditableLabel
                  value={state.vending_income[index]}
                  type="money"
                  setValue={(item) =>
                    handleChange({
                      type: "vending_income",
                      index,
                      value: item,
                    })
                  }
                />
              </td>
            ))}
            <td className="total-result">
              ${parseField(""+state?.vending_income.reduce(sumElements, 0),"money")}
            </td>
          </TableRow>
          <TableRow>
            <td>Banquet Room Rental</td>
            {state?.banquet_room_rental?.map((item, index) => (
              <td key={`tb-other-banquet_room_rental-${index}`}>
                <span>$</span>
                <EditableLabel
                  value={state.banquet_room_rental[index]}
                  type="money"
                  setValue={(item) =>
                    handleChange({
                      type: "banquet_room_rental",
                      index,
                      value: item,
                    })
                  }
                />
              </td>
            ))}
            <td className="total-result">
              ${parseField(""+state?.banquet_room_rental.reduce(sumElements, 0),"money")}
            </td>
          </TableRow>
          <TableRow>
            <td>Interest Income</td>
            {state?.interest_income?.map((item, index) => (
              <td key={`tb-other-interest_income-${index}`}>
                <span>$</span>
                <EditableLabel
                  value={state.interest_income[index]}
                  type="money"
                  setValue={(item) =>
                    handleChange({
                      type: "interest_income",
                      index,
                      value: item,
                    })
                  }
                />
              </td>
            ))}
            <td className="total-result">
              ${parseField(""+state?.interest_income.reduce(sumElements, 0),"money")}
            </td>
          </TableRow>
          <TableRow>
            <td>Miscellaneous</td>
            {state?.miscellaneous?.map((item, index) => (
              <td key={`tb-other-miscellaneous-${index}`}>
                <span>$</span>
                <EditableLabel
                  value={state.miscellaneous[index]}
                  type="money"
                  setValue={(item) =>
                    handleChange({
                      type: "miscellaneous",
                      index,
                      value: item,
                    })
                  }
                />
              </td>
            ))}
            <td className="total-result">
              ${parseField(""+state?.miscellaneous.reduce(sumElements, 0),"money")}
            </td>
          </TableRow>
          <TableRow>
            <td className="total-result">Total Other Revenue</td>
            {state?.total_other_revenue?.map((_, index) => (
              <td className="total-result-number" key={`total-td-${index}`}>
                ${parseField("" + state.total_other_revenue[index], "money")}
              </td>
            ))}
            <td className="total-result">
              ${parseField("" + state.total_other_revenue.reduce(sumElements, 0), "money")}
            </td>
          </TableRow>
          <TableRow>
            <td className="total-result">Total Revenue</td>
            {temporaryTotalRevenue?.map((_, index) => (
              <td className="total-result-number">
                ${parseField("" + temporaryTotalRevenue[index], "money")}
              </td>
            ))}
            <td className="total-result total-result-number">
              ${parseField("" + temporaryTotalRevenue.reduce(sumElements, 0), "money")}
            </td>
          </TableRow>
        </TBody>
      </Table>

      <ChartContainer>
        <Chart
          options={{
            chart: {
              id: "basic-bar",
              toolbar: {
                show: true,
                tools: {
                  download: false
                }
              },
            },
            markers: { size: 2 },
            xaxis: {
              categories: state.months,

            },
            yaxis: {
              labels: { formatter: (val) => parseField("" + val, "money") }
            },
            animations: {
              enabled: true,
              easing: "linear",
              dynamicAnimation: {
                speed: 1000,
              },
            },
            tooltip: {
              custom: function ({ series, seriesIndex, dataPointIndex, w }) {
                return `<div class="tooltip_chart">
                  Total Revenue: <span>$ ${parseField("" + series[seriesIndex][dataPointIndex], "money")}</span>
                </div>`
              }
            },
            colors: ['#00941e']
          }}
          series={[
            {
              name: "Total Revenue",
              data: temporaryTotalRevenue.map((item) => Number(item)),

            },
          ]}

          type="line"
          width="100%"
          height="100%"
        />
      </ChartContainer>
      <ButtonsContainer>
        <Button
          label="Back"
          variant="secondary"
          onClick={() => backPage({ ...state })}
        />
        <Button label="Next" onClick={() => {
          setTotalRevenueArray(temporaryTotalRevenue);
          nextPage({ ...state });
        }} />
      </ButtonsContainer>
    </TableContainer>
  );
}
